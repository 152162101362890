import React, {Component, useState} from "react";
import { connect } from "react-redux";
import {deleteToken, findTokenByWallet} from "../actions/token";
import {NotificationManager} from 'react-notifications';
import DefaultLogo from '../image/default_logo.png';
import Styles from "./style";
import Table from "./table";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactTooltip from "react-tooltip";

class ManagementTokenComponent extends Component {
    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.state = {
            data: [],
            show: false,
            deleteSubject: '',
            deleteTokenName: '',
            policySkey: undefined,
            errorMessage: ''
        };
        this.checkAccessable();
        this.getData();
    }

    checkAccessable = () => {
        if(!localStorage.getItem('walletLinked') && !localStorage.getItem('walletAddress')){
            window.location.href='/';
        };
    }
    columns = [
        {
            Header: "Name",
            accessor: "name",
            maxWidth: 200,
            minWidth: 200,
            width: 120,
            filter: "includes",
        },
        {
            Header: "Logo",
            accessor: "logo",
            Cell: row => <img className="token-logo" src={row.value ? `data:image/png;base64, ${row.value}` : DefaultLogo}></img>,
            maxWidth: 100,
            minWidth: 70,
            width: 70,
        },
        {
            Header: "Subject",
            accessor: "subject",
            Cell: (props) => {
                return (
                    <div>
                        <a className="subject-link" target="_blank" data-tip data-for={props.row.original.subject} href={props.row.original.status == 'disapproved' ? props.row.original.rejectUrl : (props.row.original.status == 'approved' ? 'https://github.com/cardano-foundation/cardano-token-registry/blob/master/mappings/' + props.row.original.subject + '.json' : '')}>{props.row.original.subject.substring(0,30) + '...'}</a>
                        {props.row.original.rejectUrl ? <ReactTooltip id={props.row.original.subject} aria-haspopup='true' type="warning">
                            <p><b>Cick to see more detail of your registry</b></p>
                        </ReactTooltip> : ''}
                    </div>
                );
            },
            filter: "includes",
            maxWidth: 400,
            minWidth: 140,
            width: 300,
        },
        {
            Header: "Updated At",
            accessor: "updated",
            Cell: row => row.value ? row.value.substring(0,19) : '',
            maxWidth: 400,
            minWidth: 200,
            width: 200,
        },
        {
            Header: "Status",
            accessor: "status",
            filter: (rows, id, filterValue) =>
                rows.filter((row) => filterValue === '' || row.values.status === filterValue),
            Cell: row => row.value === 'approved' ? 'Approved' : (row.value === 'pending' ? 'Pending' : (row.value === 'disapproved' ? 'Disapproved' : '')),
        },
        {
            Header: "Action",
            Cell: (props) =>
                { if(props.row.original.status !== 'disapproved')
                    return(
                        <button style={{fontSize: "10px"}} className="btn btn-danger btn-sm" onClick={() => {
                            this.setState({
                                show : true,
                                deleteSubject: props.row.original.subject,
                                deleteTokenName: props.row.original.name
                                });
                            }}>
                            <i className="bi bi-trash"></i>
                        </button>
                    )
                else
                    return '';
                }

        }
    ]
    handleClose = () => {
        this.setState({
            show : false,
            deleteSubject: '',
            deleteTokenName: '',
            policySkey: undefined,
            errorMessage: ''
        });
    }
    handleDelete = async () => {
        let policySkey = this.state.policySkey;
        if (!policySkey) {
            this.setState({
                errorMessage : "* Please upload policy.skey file to continue"
            });
            return;
        }
        const formData = new FormData();
        formData.append('subject', this.state.deleteSubject);
        formData.append('policySkey', policySkey);
        formData.append('updatedBy', localStorage.getItem('walletAddress'));
        document.body.style.cursor = "wait";
        await this.props
            .deleteToken(formData)
            .then((data) => {
                this.setState({
                    show : false,
                });
                NotificationManager.success("Registry deleted successfully!");
                this.getData();
                document.body.style.cursor = "default";
            })
            .catch((e) => {
                this.setState({
                    show : false,
                    deleteSubject: '',
                    deleteTokenName: '',
                    policySkey: undefined,
                    errorMessage: ''
                });
                document.body.style.cursor = "default";
                NotificationManager.error(e.response.data ? e.response.data : 'Server Error!', '', 4000, () => {
                    return;
                });
                console.log(e);
            });
    }
    handleChangeFile(e) {
        this.setState({
            policySkey: e.target.files[0],
            errorMessage : ""
        })
    }

    getData = async () => {
        await this.props
            .findTokenByWallet(localStorage.getItem('walletAddress'))
            .then((data) => {
                this.setState({
                    data: data
                });
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error(e.response && e.response.data ? e.response.data : 'Server Error!', '', 4000, () => {
                    return;
                });
            });
    };

    render() {
        return (
            <Styles>
                <h2 className="mb-4 title">Registry Manager </h2>
                <div className="table-content">
                    <Table columns={this.columns} data={this.state.data} pageSize={6} />
                </div>
                <Modal className="delete-modal" show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Token</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label className = "control-label" htmlFor="policySkey">{`Upload policy.skey to delete `}<b className="tokenName">{this.state.deleteTokenName}</b>{` token`}</label>
                        <div className="input-group">
                            <input type="file" className="form-control custom-file-policy-skey"
                                   id="policySkey"
                                   required
                                   onChange={this.handleChangeFile}
                                   name="policySkey"/>
                            <span data-tip data-for="policySkey" className="end-input"><i className="bi bi-exclamation-circle"></i></span>
                            <ReactTooltip id='policySkey' aria-haspopup='true' type="warning">
                                <p><b>Policy.skey file is  generated in policy-wise file! This item includes your signature.</b></p>
                                <p  style={{color: "red"}}><b>Your signature will not be published in any platform to token security</b></p>
                            </ReactTooltip>
                        </div>
                        <div className="errorMsg mb-4">{this.state.errorMessage ? this.state.errorMessage : ''}</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.handleDelete}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Styles>

        );
    }

}

export default connect(null, { findTokenByWallet, deleteToken })(ManagementTokenComponent);