import React from "react";
import { useTable, usePagination, useGlobalFilter, useFilters } from "react-table";

// A great library for fuzzy filtering/sorting items


// Our table component
function Table({ columns, data }) {
    const props = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 7 }
        },
        useGlobalFilter, // useGlobalFilter!
        useFilters,
        usePagination,
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        setFilter,
        filters,
        state,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter }
    } = props;
    React.useEffect(() => {
        // props.dispatch({ type: actions.resetPage })
    }, [globalFilter]);

    const statusChange = (e) => {
        setFilter('status', e);
    };
    return (
        <>
            <div className="input-group mb-3"  style={{width: "100%"}}>
                <div className="input-group-prepend">
                    <span className="input-group-text"><i className="bi bi-search"></i></span>
                </div>
                <input type="text" className="form-control" placeholder="Search by name or subject"
                    value={globalFilter || ""}
                    onChange={e => setGlobalFilter(e.target.value)}
                />
                <label style={{marginLeft: "10px"}}>Status: </label>
                <div className="end-input" >
                    <select className="form-select" onChange={e => statusChange(e.target.value)}>
                        <option value="">All</option>
                        <option value="approved">Approved</option>
                        <option value="pending">Pending</option>
                        <option value="disapproved">Disapproved</option>
                    </select>
                </div>
            </div>
            <table {...getTableProps()} className="table table-hover">
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps({
                                style: { minWidth: column.minWidth, width: column.width },
                            })}>
                                {column.render("Header")}
                                {/* Render the columns filter UI */}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                );
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className="pagination" style={{alignItems: "center"}}>
                <div className="pagination page-arrow">
                    <li className={!canPreviousPage ? "page-item disabled" : "page-item "}>
                        <a className="page-link" aria-label="Previous"  onClick={() => gotoPage(0)}>
                            <span aria-hidden="true">&laquo;</span><span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li className={!canPreviousPage ? "page-item disabled" : "page-item "}>
                        <a className="page-link" aria-label="Previous" onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li className={!canNextPage ? "page-item disabled" : "page-item "}>
                        <a className="page-link" aria-label="Next"  onClick={() => nextPage()}>
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                    <li className={!canNextPage ? "page-item disabled" : "page-item "}>
                        <a className="page-link"   aria-label="Next" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            <span aria-hidden="true">&raquo;</span><span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </div>
                <span style={{marginLeft: "10px"}}>
                  Page{" "}
                            <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
                <span style={{marginLeft: "10px"}}>
                    | Go to page:{" "}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(page);
                        }}
                        style={{ width: "100px" }}
                    />
                </span>{" "}
            </div>
        </>
    );
}



export default Table;
