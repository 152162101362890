import {
  SUBMIT_TOKEN,
} from "./types";

import TokenService from "../services/token.service";

export const submitToken = (fields) => async () => {
  try {
    const res = await TokenService.submit(fields);
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
export const deleteToken = (token) => async () => {
  try {
    const res = await TokenService.delete(token);
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findTokenByWallet = (wallet_addr) => async () => {
  try {
    const res = await TokenService.getAllByWallet(wallet_addr);
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};


export const updateTutorial = (id, data) => async (dispatch) => {
  try {
    const res = await submitToken.update(id, data);

    dispatch({
      type: SUBMIT_TOKEN,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

