import React, { Component } from "react";
import { connect } from "react-redux";
import { submitToken } from "../actions/token";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import ReactTooltip from "react-tooltip";
class SubmitTokenComponent extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.submitToken = this.submitToken.bind(this);
    this.state = {
      fields: {
        assetName: '',
        name: '',
        description: '',
        ticker: '',
        url: '',
        logo: '',
        policyScript: undefined,
        policySkey: undefined,
        decimals: 0
      },
      errors: {},
      submitted: false,
      submitting: false,
      subject: ''
    };
    this.checkAccessable();
  }
  checkAccessable = () => {
    if(!localStorage.getItem('walletLinked') && !localStorage.getItem('walletAddress')){
      window.location.href='/';
    };
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  async handleChange(e) {
    let fields = this.state.fields;
    if (e.target.name == 'policyScript' || e.target.name == 'policySkey'){
      fields[e.target.name] = e.target.files[0];
    } else if (e.target.name == 'logo') {
      fields[e.target.name] = (await this.toBase64(e.target.files[0])).split(',')[1];
    } else {
      fields[e.target.name] = e.target.value;
    }
    this.setState({
      fields
    });
    if (fields[e.target.name]){
      let errors = this.state.errors;
      errors[e.target.name] = "";
      this.setState({
        errors
      });
    }
  }

  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["name"] || fields["name"].length > 50) {
      formIsValid = false;
      errors["name"] = "* Name must be < 50 characters.";
    }

    if (!fields["description"] || fields["description"].length > 500) {
      formIsValid = false;
      errors["description"] = "*  Description must be <= 500 characters.";
    }
    if (fields["decimals"] && (fields["decimals"] < 0 || fields["decimals"] > 255)) {
      formIsValid = false;
      errors["decimals"] = "*  Decimals must be between 0 and 255.";
    }
    if (!fields["policyScript"]) {
      formIsValid = false;
      errors["policyScript"] = "* Please upload policy.script file";
    }
    if (!fields["policySkey"]) {
      formIsValid = false;
      errors["policySkey"] = "* Please upload policy.skey file";
    }
    if (fields["url"] && !fields["url"].match(/^https:\/\/.*/)) {
      formIsValid = false;
      errors["url"] = "* Url must be start with https://";
    }
    if (fields["ticker"] && (fields["ticker"].length < 2 || fields["ticker"].length > 9)) {
      formIsValid = false;
      errors["ticker"] = "* Ticker's length must be >= 2 and <= 9 characters";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }
  reloadPage = () => {
    document.location.reload();
  }
  toManagementPage = () => {
    window.location.href='/token-management'
  }
  back = () => {
    this.setState({
      subject: '',
      logo: '',
      policyScript: null,
      policySkey: null,
    })
  }

  async submitToken(e) {
    e.preventDefault();
    if (this.validateForm()) {
      const formData = new FormData();
      for (const [k, v] of Object.entries(this.state.fields)) {
        formData.append(k, v);
      }
      formData.append('updatedBy', localStorage.getItem('walletAddress'));
      this.setState({
        submitting : true
      });
      document.body.style.cursor = "wait";
      await this.props
          .submitToken(formData)
          .then((data) => {
            this.setState({
              submitting : false,
              subject : data
            });
            document.body.style.cursor = "default";
          })
          .catch((e) => {
            console.log(e);
            this.setState({
              submitting : false
            });
            document.body.style.cursor = "default";
            NotificationManager.error(e.response && e.response.data ? e.response.data : 'Server Error!', '', 4000, () => {
              return;
            });
          });
    } else {
      NotificationManager.error('Please enter all required field to submit registry', '', 4000, () => {
        return;
      });
    }
  }

  render() {
    return (
      <div className="submit-form">
        {this.state.subject ? (
          <div>
            <h4>You submitted successfully!</h4>
            <div className="note_notify">The registry is currently in review. You can check status of your submission in token management tab</div>
            <div className="subject_info mb-4">Your submitted subject:<b> {this.state.subject}</b></div>
            <button onClick={this.reloadPage} className="btn btn-info mr-3">
              Submit other token
            </button>
            <button onClick={this.toManagementPage} className="btn btn-primary mr-3">
              Token Management
            </button>
            <button onClick={this.back} className="btn btn-secondary mr-3">
              Back
            </button>
          </div>
        ) : (
          <div className="container">
            <h2 className="mb-4 title">Registry Cardano Token </h2>
            <label htmlFor="subject">Asset Name</label>
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="A human-readable asset name"
                    id="assetName"
                    value={this.state.fields.assetName || ''}
                    onChange={this.handleChange}
                    name="assetName"/>
              <span data-tip data-for="assetName" className="end-input"><i className="bi bi-exclamation-circle"></i></span>
              <ReactTooltip id='assetName' aria-haspopup='true' type="warning">
                <p><b>A human-readable name for the subject, suitable for use </b></p>
                <p><b>an interface to combined with your policyid</b></p>
              </ReactTooltip>

            </div>
            <label className = "control-label"  htmlFor="name">Name</label>
            <div className="input-group">
              <input type="text" className="form-control" placeholder="A human-readable name for token"
                    id="name"
                    required
                    value={this.state.fields.name || ''}
                    onChange={this.handleChange}
                    name="name"/>
              <span data-tip data-for="name" className="end-input"><i className="bi bi-exclamation-circle"></i></span>
              <ReactTooltip id='name' aria-haspopup='true' type="warning">
                <p><b>A human-readable name for the subject, </b></p>
                <p><b>suitable for use in an interface</b></p>
              </ReactTooltip>
            </div>
            <div className="errorMsg mb-3">{this.state.errors.name || ''}</div>
            <label className = "control-label"  htmlFor="description">Description</label>
            <div className="input-group">
              <input type="text" className="form-control" placeholder="Enter description for token"
                        id="description"
                        required
                        value={this.state.fields.description || ''}
                        onChange={this.handleChange}
                        name="description"/>
              <span data-tip data-for="description" className="end-input"><i className="bi bi-exclamation-circle"></i></span>
              <ReactTooltip id='description' aria-haspopup='true' type="warning">
                <p><b>A human-readable description for the subject,</b></p>
                <p><b>suitable for use in an interface</b></p>
              </ReactTooltip>
            </div>
            <div className="errorMsg mb-3">{this.state.errors.description || ''}</div>
            <label htmlFor="ticker">Ticker</label>
            <div className="input-group">
              <input type="text" className="form-control" placeholder="Enter ticker for token"
                        id="ticker"
                        value={this.state.fields.ticker || ''}
                        onChange={this.handleChange}
                        name="ticker"/>
              <span data-tip data-for="ticker" className="end-input"><i className="bi bi-exclamation-circle"></i></span>
              <ReactTooltip id='ticker' aria-haspopup='true' type="warning">
                <p><b>A human-readable ticker name for the subject, </b></p>
                <p><b>suitable for use in an interface</b></p>
              </ReactTooltip>
            </div>
            <div className="errorMsg mb-3">{this.state.errors.ticker || ''}</div>
            <label htmlFor="url">Url</label>
            <div className="input-group">
              <input type="text" className="form-control" placeholder="Enter url for token"
                        id="url"
                        value={this.state.fields.url || ''}
                        onChange={this.handleChange}
                        name="url"/>
              <span data-tip data-for="url" className="end-input"><i className="bi bi-exclamation-circle"></i></span>
              <ReactTooltip id='url' aria-haspopup='true' type="warning">
                <p><b>A HTTPS URL (web page relating to the token)</b></p>
              </ReactTooltip>
            </div>
            <div className="errorMsg mb-3">{this.state.errors.url || ''}</div>
            <label htmlFor="decimals">Decimals</label>
            <div className="input-group">
              <input type="number" min="0" data-bind="value:replyNumber" className="form-control" placeholder="Enter deciamals number"
                        id="decimals"
                        value={this.state.fields.decimals || ''}
                        onChange={this.handleChange}
                        name="decimals"/>
              <span data-tip data-for="decimals" className="end-input"><i className="bi bi-exclamation-circle"></i></span>
              <ReactTooltip id='decimals' aria-haspopup='true' type="warning">
                <p><b>How many decimals to the token</b></p>
              </ReactTooltip>
            </div>
            <div className="errorMsg mb-3">{this.state.errors.decimals || ''}</div>
            <label htmlFor="logo">Logo</label>
            <div className="input-group">
              <input type="file" className="form-control custom-file-logo"
                        id="logo"
                        accept="image/png"
                        onChange={this.handleChange}
                        name="logo"/>
              <span  data-tip data-for="logo" className="end-input"><i className="bi bi-exclamation-circle"></i></span>
              <ReactTooltip id='logo' aria-haspopup='true' type="warning">
                <p><b>A png image file</b></p>
              </ReactTooltip>
            </div>
            <div className="errorMsg mb-4">{this.state.errors.logo || ''}</div>
            <label className = "control-label" htmlFor="policyScript">Upload policy.script</label>
            <div className="input-group">
              <input type="file" className="form-control custom-file-policy-script"
                        id="policyScript"
                        required
                        onChange={this.handleChange}
                        name="policyScript"/>
              <span data-tip data-for="policyScript" className="end-input"><i className="bi bi-exclamation-circle"></i></span>
              <ReactTooltip id='policyScript' aria-haspopup='true' type="warning">
                <p><b>Policy.script file is generated in minting process including policyid hash!</b></p>
                <p><b>This item will be compulsory for token’s subject</b></p>
              </ReactTooltip>
            </div>
            <div className="errorMsg mb-4">{this.state.errors.policyScript || ''}</div>
            <label className = "control-label" htmlFor="policySkey">Upload policy.skey</label>
            <div className="input-group">
              <input type="file" className="form-control custom-file-policy-skey"
                        id="policySkey"
                        required
                        onChange={this.handleChange}
                        name="policySkey"/>
              <span data-tip data-for="policySkey" className="end-input"><i className="bi bi-exclamation-circle"></i></span>
              <ReactTooltip id='policySkey' aria-haspopup='true' type="warning">
                <p><b>Policy.skey file is  generated in policy-wise file! This item includes your signature.</b></p>
                <p  style={{color: "red"}}><b>Your signature will not be published in any platform for the token security</b></p>
              </ReactTooltip>
            </div>
            <div className="errorMsg mb-4">{this.state.errors.policySkey || ''}</div>
            <div className="submit-btn">
              <button disabled={this.state.submitting}  onClick={this.submitToken} className="btn text-center">
                {this.state.submitting ? 'Submitting' : 'Submit'}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(null, { submitToken })(SubmitTokenComponent);
