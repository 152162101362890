import http from "../http-common";
import axiosInstance from '../configs/config';

class TokenService {
  async getAllByWallet(wallet_addr) {
    const headers = {
      'Content-Type': 'application/json'
    }
    return await axiosInstance.get(`/token/find-by-wallet/${wallet_addr}`, {
      headers: headers
    });
  }

  get(id) {
    return http.get(`/tutorials/${id}`);
  }

  async submit(data) {
    const headers = {
      'Content-Type': 'multipart/form-data'
    }
    return await axiosInstance.post('/token/submit', data, {
      headers: headers
    });
  }

  async delete(data) {
    const headers = {
      'Content-Type': 'multipart/form-data'
    }
    return await axiosInstance.post('/token/delete', data, {
      headers: headers
    });
  }

  update(id, data) {
    return http.put(`/tutorials/${id}`, data);
  }
}

export default new TokenService();