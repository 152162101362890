import styled from "styled-components";

const Styles = styled.div`
          padding: 1rem;
          table {
            border-spacing: 0;
            border-collapse: unset;
            th,
            td {
              margin: 0;
              padding: 0.5rem;
            }
          }
        `;
export default Styles;