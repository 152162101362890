import {
  SUBMIT_TOKEN
} from "../actions/types";

const initialState = [];

function tokenReducer(tokens = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SUBMIT_TOKEN:
      return payload;

    // case RETRIEVE_TUTORIALS:
    //   return payload;
    //
    // case UPDATE_TUTORIAL:
    //   return tutorials.map((tutorial) => {
    //     if (tutorial.id === payload.id) {
    //       return {
    //         ...tutorial,
    //         ...payload,
    //       };
    //     } else {
    //       return tutorial;
    //     }
    //   });
    //
    // case DELETE_TUTORIAL:
    //   return tutorials.filter(({ id }) => id !== payload.id);
    //
    // case DELETE_ALL_TUTORIALS:
    //   return [];

    default:
      return tokens;
  }
};

export default tokenReducer;