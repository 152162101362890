import React, {Component} from "react";

class Home extends Component {

    render() {
        return (
            <div>
                <div className="sc-1xf18x6-0 sc-1twd32i-0 haVRLx kKpYwv Featured--container">
                    <div className="sc-1xf18x6-0 sc-1twd32i-0 haVRLx kKpYwv Featured--title">
                        <h4 className="sc-1xf18x6-0 sc-1w94ul3-0 haVRLx jVuaKH Featured--header">Cardano offchain metadata registry</h4>
                        <span className="sc-1xf18x6-0 sc-1w94ul3-0 haVRLx gJNgrK Featured--subheader">Manage and view <a href="https://github.com/cardano-foundation/CIPs/tree/master/CIP-0026" className="cip26_link" target="_blank">CIP-26</a> compliant offchain metadata</span>
                        <div className="fresnel-container fresnel-greaterThanOrEqual-lg ">
                            <div height="100%" className="sc-1xf18x6-0 sc-1twd32i-0 kSASPF kKpYwv">
                                <a className="sc-1pie21o-0 elyzfO Featured--learn-more home-learn-more" href="https://cardanofoundation.org/" target="_blank">
                                    <div className="sc-1xf18x6-0 sc-1twd32i-0 haVRLx kKpYwv Featured--learn-more-container"><i
                                        className="sc-1gugx8q-0 gNXDak Featured--learn-more-icon material-icons Featured--learn-more-icon"
                                        color="blue" value="play_circle_filled" size="24">play_circle_filled</i>
                                        <p className="sc-1xf18x6-0 sc-1w94ul3-0 haVRLx kJaKrw Featured--learn-more-text">Learn
                                            more about Cardano Foundation</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
